import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeaderService {
	readonly title$ = new BehaviorSubject<string | null>(null);
	readonly showHeaderInPrint$ = new BehaviorSubject<boolean>(true);

	get title() {
		return this.title$.value;
	}

	set title(title) {
		this.title$.next(title);
	}

	hasTabBar$ = new BehaviorSubject<boolean>(null);

	get hasTabBar() {
		return this.hasTabBar$.value;
	}

	set hasTabBar(hasTabBar) {
		this.hasTabBar$.next(hasTabBar);
	}

	showingLogo$ = new BehaviorSubject<boolean>(false);

	get showingLogo() {
		return this.showingLogo$.value;
	}

	set showingLogo(showingLogo) {
		this.showingLogo$.next(showingLogo);
	}

	customBackHandler: () => void | boolean;
}
